import React from "react";
import MainRouter from "../Router";

function App() {
  return (
    <>
      <MainRouter/>
    </>
  );
}

export default App;
